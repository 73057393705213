var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"app-container uploadProShowComponent app-flex-col-container"},[_c('el-tabs',{attrs:{"type":"card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_vm._l((_vm.structs),function(ref,index){
var name = ref.name;
var showImageGroupList = ref.showImageGroupList;
var status = ref.status;
return _c('el-tab-pane',{key:index + '',attrs:{"name":index + '',"label":("款式：" + name),"disabled":!showImageGroupList.length}},[_c('span',{attrs:{"slot":"label"},on:{"click":function($event){return _vm.clickHandler(showImageGroupList, status)}},slot:"label"},[_vm._v(_vm._s(("款式：" + (name + (status == '1' ? '(已上架)' : '')))))]),_vm._l((_vm.forms[index] || []),function(form,sIndex){return _c('div',{key:sIndex,staticClass:"struct-item-wrapper"},[(_vm.showDelBtn(index))?_c('el-button',{staticClass:"del-btn",attrs:{"type":"danger","size":"mini","disabled":status == '1'},on:{"click":function($event){return _vm.delHandler(index, sIndex)}}},[_vm._v("删除")]):_vm._e(),_c('CalcProshow',{staticClass:"calc-btn",attrs:{"klMasksData":_vm.klMasksData,"knifeData":_vm.knifeData,"whiteShow":_vm.whiteShow(form.white_show),"coordShow":_vm.coordShow(form.coord_show),"shadowShow":_vm.coordShow(form.shadow_show),"frontDesign":_vm.frontDesign,"showMasks":_vm.showMasks(form.show_masks || [])}}),(_vm.urls[index] && _vm.urls[index][sIndex])?_c('el-image',{attrs:{"fit":"contain","src":_vm.urls[index][sIndex]}}):_vm._e(),_c('BaseForm',{ref:"form",refInFor:true,attrs:{"customClass":"customClass","label-width":"100px","pos":index,"sPos":sIndex,"cols":_vm.formField,"form":form},scopedSlots:_vm._u([{key:"coord_showSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"prop":prop,"needPicSIze":true,"files":form[prop],"hideDialog":status == '1',"limit":1},on:{"update:files":function($event){return _vm.$set(form, prop, $event)}}})]}},{key:"white_showSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"accept":"image/png, image/jpg, image/jpeg","prop":prop,"needPicSIze":true,"files":form[prop],"hideDialog":status == '1',"limit":1},on:{"update:files":function($event){return _vm.$set(form, prop, $event)}}})]}},{key:"shadow_showSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"prop":prop,"needPicSIze":true,"accept":"image/png","files":form[prop],"hideDialog":status == '1',"limit":1},on:{"update:files":function($event){return _vm.$set(form, prop, $event)}}})]}},{key:"show_masksSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [(_vm.showChooseDirBtn(index, sIndex, status) == 1)?_c('FolderUploadBtn',{attrs:{"id":("formId" + index + "_" + sIndex)},on:{"changeHandler":function($event){return _vm.changeHandler($event, sIndex)}}},[_c('span',[_c('i',{staticClass:"el-icon-plus"}),_c('span',[_vm._v("选择效果图文件夹")])])]):_vm._e(),(_vm.showChooseDirBtn(index, sIndex, status) == -1)?_c('el-button',{staticStyle:{"height":"32px"},attrs:{"type":"info","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.prompt}},[_vm._v("选择效果图文件夹")]):_vm._e(),_c('div',{staticClass:"config-list"},_vm._l((form[prop] || []),function(fileGroup,gIndex){return _c('div',{key:gIndex,staticClass:"config-item"},[_c('FileItem',{attrs:{"data":fileGroup}})],1)}),0)]}}],null,true)})],1)})],2)}),_c('el-tab-pane',{attrs:{"name":"addNewTab","disabled":""}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('el-button',{attrs:{"icon":"el-icon-plus","size":"mini","type":"primary","disabled":_vm.currentStatus === 1},on:{"click":_vm.addHandler}},[_vm._v("添加效果图")])],1)])],2),_c('div',{staticClass:"bottom"},[_c('el-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")]),(_vm.showConfirmBtn)?_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.doSubmit}},[_vm._v("保存")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }