<template>
  <div class="app-container uploadProShowComponent app-flex-col-container" v-loading="loading">
    <el-tabs type="card" v-model="activeName">
      <el-tab-pane
        :name="index + ''"
        :key="index + ''"
        :label="`款式：${name}`"
        :disabled="!showImageGroupList.length"
        v-for="({ name, showImageGroupList, status }, index) in structs"
      >
        <span slot="label" @click="clickHandler(showImageGroupList, status)">{{
          `款式：${name + (status == '1' ? '(已上架)' : '')}`
        }}</span>
        <div class="struct-item-wrapper" :key="sIndex" v-for="(form, sIndex) in forms[index] || []">
          <el-button
            v-if="showDelBtn(index)"
            type="danger"
            size="mini"
            class="del-btn"
            @click="delHandler(index, sIndex)"
            :disabled="status == '1'"
            >删除</el-button
          >

          <CalcProshow
            class="calc-btn"
            :klMasksData="klMasksData"
            :knifeData="knifeData"
            :whiteShow="whiteShow(form.white_show)"
            :coordShow="coordShow(form.coord_show)"
            :shadowShow="coordShow(form.shadow_show)"
            :frontDesign="frontDesign"
            :showMasks="showMasks(form.show_masks || [])"
          />

          <el-image fit="contain" v-if="urls[index] && urls[index][sIndex]" :src="urls[index][sIndex]"></el-image>
          <BaseForm
            customClass="customClass"
            ref="form"
            label-width="100px"
            :pos="index"
            :sPos="sIndex"
            :cols="formField"
            :form="form"
          >
            <template #coord_showSlot="{ scoped: { prop } }">
              <ImgUpload
                :prop="prop"
                :needPicSIze="true"
                :files.sync="form[prop]"
                :hideDialog="status == '1'"
                :limit="1"
              />
            </template>
            <template #white_showSlot="{ scoped: { prop } }">
              <ImgUpload
                accept="image/png, image/jpg, image/jpeg"
                :prop="prop"
                :needPicSIze="true"
                :files.sync="form[prop]"
                :hideDialog="status == '1'"
                :limit="1"
              />
            </template>
            <template #shadow_showSlot="{ scoped: { prop } }">
              <ImgUpload
                :prop="prop"
                :needPicSIze="true"
                accept="image/png"
                :files.sync="form[prop]"
                :hideDialog="status == '1'"
                :limit="1"
              />
            </template>

            <template #show_masksSlot="{ scoped: { prop } }">
              <FolderUploadBtn
                v-if="showChooseDirBtn(index, sIndex, status) == 1"
                :id="`formId${index}_${sIndex}`"
                @changeHandler="changeHandler($event, sIndex)"
              >
                <span>
                  <i class="el-icon-plus"></i>
                  <span>选择效果图文件夹</span>
                </span>
              </FolderUploadBtn>
              <el-button
                type="info"
                v-if="showChooseDirBtn(index, sIndex, status) == -1"
                style="height: 32px"
                icon="el-icon-plus"
                size="mini"
                @click="prompt"
                >选择效果图文件夹</el-button
              >
              <div class="config-list">
                <div class="config-item" :key="gIndex" v-for="(fileGroup, gIndex) in form[prop] || []">
                  <FileItem :data="fileGroup" />
                </div>
              </div>
            </template>
          </BaseForm>
        </div>
      </el-tab-pane>

      <el-tab-pane name="addNewTab" disabled>
        <span slot="label">
          <el-button @click="addHandler" icon="el-icon-plus" size="mini" type="primary" :disabled="currentStatus === 1"
            >添加效果图</el-button
          >
        </span>
      </el-tab-pane>
    </el-tabs>
    <div class="bottom">
      <el-button @click="$router.back()">返回</el-button>
      <el-button v-if="showConfirmBtn" type="primary" @click="doSubmit" :loading="loading">保存</el-button>
    </div>
  </div>
</template>

<script>
import ImgUpload from '@/components/fileUpload/imgUpload'
import FileUpload from '@/components/fileUpload/fileUpload'
import FolderUploadBtn from '@/components/folderUploadBtn'

import FileItem from './module/fileItem'
import CalcProshow from './module/calcProshow'

import cloneDeep from 'lodash/cloneDeep'
import { getListByProtoId as list, pictureUploaded as upload } from '@/api/product/protoStructApi'
import { uploadProShowField as formField } from '../field'

import calcProshowMixin from './mixins/calcProshowMixin'

import {
  file2Content,
  getPrimShowImgs,
  getRedImgData as getImgDom,
  getImgData,
  getFileNameWithoutExpand,
  isFile,
  getURLBlod,
  getFileSuffix,
  createRandomNum
} from '@/utils'
import { KNIFE_PRO_SHOW_ITEM, SHELVES } from '@/utils/constant'

import { add, del, edit, change } from '@/api/product/primShowApi'

const SUFFIX = 'json'

export default {
  name: 'uploadProShow',
  mixins: [calcProshowMixin],
  components: {
    ImgUpload,
    FileUpload,
    FolderUploadBtn,
    FileItem,
    CalcProshow
  },

  data() {
    return {
      formField,
      loading: false,
      structs: [],
      forms: [],
      oForms: [],
      urls: [],
      currentStatus: 0,
      activeName: '0',
      uploadedOssPicList: {},
      currentAppendList: {}
    }
  },
  created() {
    // this.updateCheckRules()
    this.list()
  },
  computed: {
    showDelBtn() {
      return (index) => {
        return !!this.forms[index] && this.forms[index].length > 1
      }
    },
    showChooseDirBtn() {
      return (index, sIndex, status) => {
        if (status == 1) return false
        const curForm = this.forms[index] && this.forms[index][sIndex]
        if (!curForm) {
          return false
        }
        if (!(curForm.coord_show.length && curForm.white_show.length && curForm.shadow_show.length)) {
          return -1
        }
        return 1
      }
    },
    showConfirmBtn({ structs }) {
      return structs.some(item => item.status !== SHELVES)
    }
  },
  methods: {
    //添加尺码一致的校验
    updateCheckRules(func) {
      const checkedPorp = {
        coord_show: 'coord_show',
        white_show: 'white_show',
        shadow_show: 'shadow_show'
      }
      this.formField.map((field) => {
        const needCheckProp = checkedPorp[field.prop]
        if (needCheckProp) {
          field.rule[1] = {
            validator: func
          }
        }
      })
      console.log('this.formField', this.formField)
    },

    watchForms() {
      this.$watch(
        'forms',
        () => {
          this.uploadCoordPic()
          this.updateUrls()
        },
        {
          deep: true
        }
      )
    },
    clickHandler(group, status) {
      if (!(group && group.length)) {
        this.$message.warning('当前款式坐标图未创建，请先创建坐标图，再进行此操作。')
        return
      }
      this.currentStatus = status
    },
    prompt() {
      this.$message.warning('请先上传坐标图、背景图和效果图，再进行此操作！')
    },
    addHandler() {
      const curFormArr = this.forms[this.activeName]
      const styleId = this.structs[this.activeName] && this.structs[this.activeName].id
      curFormArr.push(
        cloneDeep({
          ...KNIFE_PRO_SHOW_ITEM,
          styleId
        })
      )
    },
    delHandler(index, sIndex) {
      this.forms[index].splice(sIndex, 1)
    },
    updateUrls() {
      const formsLen = this.forms.length
      const urlsLen = this.urls.length
      if (formsLen > urlsLen) {
        for (let i = 0; i < formsLen - urlsLen; i++) {
          this.urls.push([])
        }
      }
      this.forms.map((fromGroup, index) => {
        const curUrlArr = this.urls[index]
        fromGroup.map((form, sIndex) => {
          if (!form.white_show.length) {
            curUrlArr.splice(sIndex, 1, '')
            return
          }
          if (!curUrlArr[sIndex]) {
            curUrlArr.splice(
              sIndex,
              1,
              isFile(form.white_show[0])
                ? URL.createObjectURL(form.white_show[0])
                : form.white_show[0] && form.white_show[0].path
            )
          }
        })
      })
    },
    uploadCoordPic() {
      this.forms.map((fromGroup, index) => {
        const curUrlArr = this.urls[index]
        fromGroup.map((form, sIndex) => {
          if (!form.white_show.length) {
            return
          }
          if (form.show_masks.length) {
            if (!curUrlArr[sIndex]) {
              this.proShowHandler(form.show_masks, index, sIndex)
              return
            }
            if (curUrlArr[sIndex].indexOf('http') >= 0) {
              this.proShowHandler(form.show_masks, index, sIndex)
              return
            }
          }
        })
      })
    },

    //是否需要校验
    needValid(form) {
      const { coord_show = [], white_show = [], shadow_show = [], show_masks = [] } = form
      return (
        (coord_show && coord_show.length > 0) ||
        (white_show && white_show.length) ||
        (shadow_show && shadow_show.length) ||
        (show_masks && show_masks.length)
      )
    },
    //是否所有列表都为空
    isFormsEmpty(forms) {
      let num = 0
      forms.map((formGroup) => {
        formGroup.map((form) => {
          if (this.needValid(form)) {
            num++
          }
        })
      })
      return !num
    },

    async validate() {
      const { form } = this.$refs
      const forms = Array.isArray(form) ? form : [form]
      const successData = await Promise.all(
        forms.map(async (node) => {
          const isNeed = this.needValid(node.$props.form)
          if (!isNeed) return true
          try {
            return await ((node) => {
              //校验三张图一直的宽高
              const sizeVerification = (rule, value, callback) => {
                // const checkedPorp = {
                //   coord_show: 'coord_show',
                //   white_show: 'white_show',
                //   shadow_show: 'shadow_show'
                // }
                console.log('value', value)

                const { pos, sPos } = node.$attrs
                const form = this.forms[pos][sPos]
                const {
                  coord_show: [coordPic],
                  white_show: [whitePic],
                  shadow_show: [shadowPic]
                } = form
                if (!coordPic || !whitePic || !shadowPic) {
                  return callback(new Error('三张图片尺寸不一致'))
                }
                const { width: coordPicW, height: coordPicH } = coordPic
                const { width: whitePicW, height: whitePicH } = whitePic
                const { width: shadowshowW, height: shadowshowH } = shadowPic
                console.log('coordPicW', coordPicW)
                console.log('whitePicW', whitePicW)
                console.log('shadowshowW', shadowshowW)
                console.log('coordPicH', coordPicH)
                console.log('whitePicH', whitePicH)
                console.log('shadowshowH', shadowshowH)
                if (
                  coordPicW != whitePicW ||
                  coordPicW != shadowshowW ||
                  coordPicH != whitePicH ||
                  coordPicH != shadowshowH
                ) {
                  const namePorp = {
                    coord_show: '坐标图',
                    white_show: '背景图',
                    shadow_show: '效果图'
                  }
                  const [{ prop, width, height }] = value
                  return callback(new Error(`尺寸不一致，${namePorp[prop]}是${width} * ${height}`))
                }
                // console.log(this.forms)
                return callback()
              }
              this.updateCheckRules(sizeVerification)
              return node.validate()
            })(node)
          } catch (err) {
            console.log('err', err)
            return false
          }
        })
      )
      return successData
    },

    async changeHandler(e, index) {
      const curFormArr = this.forms[this.activeName]
      const curDoingForm = curFormArr[index]
      const files = Array.from(e.target.files || [])
      const names = [...new Set(files.map(({ name }) => getFileNameWithoutExpand(name)).filter((name) => name))]
      const filesGroup = []
      names.map((name) => {
        filesGroup.push([])
        files.map((file) => {
          if (getFileNameWithoutExpand(file.name) == name) {
            filesGroup[filesGroup.length - 1].push(file)
          }
        })
      })
      this.$set(
        curDoingForm,
        'show_masks',
        filesGroup.filter((group) => {
          if (group && group.length == 2) {
            const names = group.map((file) => getFileSuffix(file.name))
            return names.includes('json') && (names.includes('png') || names.includes('jpg'))
          }
          return false
        })
      )
      this.proShowHandler(filesGroup, this.activeName, index)
    },
    async proShowHandler(filesGroup, index, sIndex) {
      const curFormArr = this.forms[index]
      const curDoingForm = curFormArr[sIndex]
      let showPathData = await getImgData(
        isFile(curDoingForm.white_show[0])
          ? URL.createObjectURL(curDoingForm.white_show[0])
          : curDoingForm.white_show[0].path
      )
      let allFilesP = filesGroup.map(async (files) => {
        if (Array.isArray(files)) {
          const tmpArr = []
          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (isFile(file)) {
              tmpArr.push(file)
            }
            if (!isFile(file)) {
              if (getFileSuffix(file.path || '') === SUFFIX) {
                tmpArr.push(await getURLBlod(file.path))
              } else {
                tmpArr.push(file)
              }
            }
          }
          return tmpArr
        }
      })
      filesGroup = await Promise.all(allFilesP)
      let configJsons = []
      let doms = []
      const c = document.createElement('canvas')
      const ctx = c.getContext('2d')
      c.setAttribute('id', createRandomNum() + '')
      const { width, height } = showPathData
      c.width = width
      c.height = height
      const pArr = filesGroup.map(async (files, index) => {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]

          if (file.type === 'application/json') {
            const configJson = await file2Content(file)
            configJsons[index] = configJson
          }
          if (file.type === 'image/png' || getFileSuffix(file.path) === 'png') {
            let dom = await getImgDom(isFile(file) ? URL.createObjectURL(file) : file.path)
            // dom.setAttribute('crossOrigin', 'Anonymous')
            doms[index] = dom
          }
        }
        return true
      })
      await Promise.all(pArr)
      ctx.putImageData(showPathData, 0, 0)
      setTimeout(() => {
        doms.map((dom, index) => {
          const { show_mask_left, show_mask_top } = JSON.parse(configJsons[index] || '{}')
          ctx.drawImage(dom, show_mask_left, show_mask_top)
        })
        setTimeout(() => {
          this.urls[index].splice(sIndex, 1, c.toDataURL())
        }, 50)
      }, 200)
    },

    async doSubmit() {
      this.loading = true
      try {
        const validData = await this.validate()
        const validIndex = validData.findIndex((bool) => {
          return bool == false
        })

        console.log(' this.forms', this.forms)
        if (validIndex >= 0) {
          const { form } = this.$refs
          const forms = Array.isArray(form) ? form : [form]
          const posArr = []
          validData.map((bool, index) => {
            if (!bool) {
              posArr.push(forms[index].$attrs.pos)
            }
          })
          this.activeName = Math.min(...posArr) + ''
          this.loading = false
          return
        }
        //所有上传数据都为空
        const isEmpty = this.isFormsEmpty(this.forms)
        if (isEmpty) {
          this.$message.warning('至少需要上传一张效果图的数据!')
          this.loading = false
          return
        }

        const p = this.forms.map(async (formGroup, index) => {
          if (
            !(
              this.structs[index] &&
              this.structs[index].showImageGroupList &&
              this.structs[index].showImageGroupList.length
            )
          )
            return false
          const { showImageGroupList: [{ id: primGroup }] = [{}] } = this.structs[index] || {}
          this.primGroup = primGroup
          const oFormGroup = (this.oForms[index] || []).filter((form) => this.needValid(form))
          const newFormGroup = formGroup.filter((form) => {
            form.primGroup = primGroup
            return this.needValid(form)
          })
          if (!oFormGroup.length && !newFormGroup.length) {
            return true
          }
          console.log('newFormGroup, oFormGroup', newFormGroup, oFormGroup)
          return await this.doFunc(newFormGroup, oFormGroup)
        })
        const res = await Promise.all(p)
        const success = res.every((bool) => bool)
        if (success) {
          this.$message.success('操作成功')
          this.$router.back()
        } else {
          this.$message.warning('发生错误，请刷新页面后重新操作')
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },

    async doFunc(formGroup, oFormGroup) {
      try {
        const dArrP = await this.doAdd(formGroup, oFormGroup)
        const res = await Promise.all([...this.doDel(formGroup, oFormGroup), ...dArrP])
        return res.every(({ code }) => $SUC({ code }))
      } catch (err) {
        this.$message.error(err.msg || err || '发生错误')
        return false
      }
    },

    doDel(formGroup, oFormGroup) {
      const delArr = oFormGroup.filter(({ id }) => {
        return formGroup.every((item) => item.id != id)
      })
      return delArr.map(({ id }) => del(id))
    },

    async doAdd(formGroup) {
      let pArr = []
      const tmpArr = []

      const [firstAdd, ...otherAdd] = formGroup
      console.log('firstAdd', firstAdd)
      const addArr = otherAdd.filter(({ id }) => {
        return !id
      })
      const editArr = otherAdd.filter(({ id }) => {
        return id
      })
      const marksEditArr = formGroup.filter(({ id, show_masks }) => {
        return id && show_masks.every((showMask) => showMask.some((path) => isFile(path)))
      })
      if (firstAdd.id) {
        const { id, primGroup, styleId } = firstAdd
        const formData = {}
        formData.isCover = firstAdd.isCover
        formData.id = id
        formData.groupId = primGroup
        formData.styleId = styleId
        formData.prototypeId = this.$route.query.protoId
        const isChange = await this.appendFormData(formData, firstAdd)
        if (!firstAdd.isCover || isChange) {
          tmpArr.push(edit(formData))
        }
      }

      if (!firstAdd.id) {
        const formData = {}
        await this.addAppendFormData(formData, firstAdd)
        formData.isCover = true
        formData.groupId = firstAdd.primGroup
        formData.styleId = firstAdd.styleId
        formData.prototypeId = this.$route.query.protoId
        console.log('伟大的萨多', formData)
        tmpArr.push(add(formData))
      }

      //添加列表
      const addArrP = addArr.map(async (form) => {
        const formData = {}
        await this.addAppendFormData(formData, form)
        formData.isCover = false
        formData.groupId = form.primGroup
        formData.styleId = form.styleId
        formData.prototypeId = this.$route.query.protoId
        console.log('伟大的萨多', formData)
        tmpArr.push(add(formData))
      })

      pArr = pArr.concat(addArrP)

      const editArrP = editArr.map(async (form) => {
        const { id } = form
        const formData = {}
        const isChange = await this.appendFormData(formData, form)
        if (isChange) {
          formData.groupId = form.primGroup
          formData.styleId = form.styleId
          formData.isCover = false
          formData.prototypeId = this.$route.query.protoId
          formData.id = id
          tmpArr.push(edit(formData))
        }
      })

      pArr = pArr.concat(editArrP)

      const marksEditArrP = marksEditArr.map(async (form) => {
        const { id } = form
        const formData = {}
        formData.groupItemId = id
        await this.markAppendFormData(formData, form)
        tmpArr.push(change(formData))
      })

      pArr = pArr.concat(marksEditArrP)

      await Promise.all(pArr)
      return tmpArr
    },

    async addAppendFormData(formData, form) {
      const {
        coord_show: [coord_show],
        white_show: [white_show],
        shadow_show: [shadow_show],
        show_masks = []
      } = form

      const tmpArr = []

      if (isFile(coord_show)) {
        // formData.append('coord_show', coord_show)
        tmpArr.push({
          prop: 'coordinateImagePath',
          files: [coord_show],
          dirPrefix: $ossDirMapWithType['2']
        })
      }
      if (isFile(white_show)) {
        // formData.append('white_show', white_show)
        tmpArr.push({
          prop: 'whiteBoardImagePath',
          files: [white_show],
          dirPrefix: $ossDirMapWithType['3']
        })
      }
      if (isFile(shadow_show)) {
        // formData.append('shadow_show', shadow_show)
        tmpArr.push({
          prop: 'transparentShadowImagePath',
          files: [shadow_show],
          dirPrefix: $ossDirMapWithType['4']
        })
      }

      const showMaskFiles = []
      show_masks.map((showMask) => {
        showMask.map((file) => {
          if (isFile(file)) {
            showMaskFiles.push(file)
          }
        })
      })

      if (showMaskFiles.length) {
        tmpArr.push({
          prop: 'maskList',
          files: showMaskFiles,
          dirPrefix: $ossDirMapWithType['5']
        })
      }

      if (tmpArr.length) {
        const uploadedOssPicList = await $uploadOSSPics(tmpArr)
        let tempArr = []
        uploadedOssPicList.maskList.map((item, index) => {
          if (index % 2 === 0) {
            tempArr.push({
              maskJsonPath: uploadedOssPicList.maskList[index],
              maskImagePath: uploadedOssPicList.maskList[index + 1]
            })
          }
        })
        formData.maskList = tempArr
        formData.coordinateImagePath = uploadedOssPicList.coordinateImagePath
        formData.whiteBoardImagePath = uploadedOssPicList.whiteBoardImagePath
        formData.transparentShadowImagePath = uploadedOssPicList.transparentShadowImagePath
      }
    },

    async appendFormData(formData, form) {
      const tmpArr = []
      let isAppend = false
      const {
        coord_show: [coord_show],
        white_show: [white_show],
        shadow_show: [shadow_show]
      } = form
      if (isFile(coord_show)) {
        tmpArr.push({
          prop: 'coordinateImagePath',
          files: [coord_show],
          dirPrefix: $ossDirMapWithType['2']
        })
        isAppend = true
      }
      if (isFile(white_show)) {
        tmpArr.push({
          prop: 'whiteBoardImagePath',
          files: [white_show],
          dirPrefix: $ossDirMapWithType['3']
        })
        isAppend = true
      }
      if (isFile(shadow_show)) {
        tmpArr.push({
          prop: 'transparentShadowImagePath',
          files: [shadow_show],
          dirPrefix: $ossDirMapWithType['4']
        })
        isAppend = true
      }

      if (tmpArr.length) {
        this.currentAppendList = (await $uploadOSSPics(tmpArr)) || {}
        Object.assign(formData, { ...this.currentAppendList })
      }
      return isAppend
    },

    async markAppendFormData(formData, form) {
      const { show_masks = [], id } = form
      const tmpArr = []
      const showMaskFiles = []
      show_masks.map((showMask) => {
        showMask.map((file) => {
          if (isFile(file)) {
            showMaskFiles.push(file)
          }
        })
      })

      if (showMaskFiles.length) {
        tmpArr.push({
          prop: 'maskList',
          files: showMaskFiles,
          dirPrefix: $ossDirMapWithType['5']
        })
      }

      if (tmpArr.length) {
        let showMasks = (await $uploadOSSPics(tmpArr)) || {}
        // Object.assign(formData, showMasks)
        let tempArr = []
        showMasks.maskList.map((item, index) => {
          if (index % 2 === 0) {
            tempArr.push({
              maskJsonPath: showMasks.maskList[index],
              maskImagePath: showMasks.maskList[index + 1]
            })
          }
        })
        formData.maskList = tempArr
      }
    },

    formatResult(result) {
      const { styleList = [] } = result
      styleList.map(({ showImageGroupList }) => {
        showImageGroupList.map(({ showImageGroupItemList } = []) => {
          showImageGroupItemList.map((item) => {
            if (!item) return
            item.coord_show = item.coordinateImagePath
            item.show_masks = item.showImageGroupItemMaskList
            item.shadow_show = item.transparentShadowImagePath
            item.white_show = item.whiteBoardImagePath
            item.show_masks.map((showMask) => {
              showMask.map_json = showMask.maskJsonPath
              showMask.mask = showMask.maskImagePath
            })
          })
        })
      })
    },

    async list() {
      this.loading = true
      try {
        const id = Number(this.$route.query.referenceId)
        const data = {
          id
        }
        if (!id) {
          return this.$router.back()
        }
        const { code, detail = [] } = (await list(data)) || {}

        //格式化返回的数据
        this.formatResult(detail)
        if ($SUC({ code })) {
          this.structs = detail.styleList
          this.currentStatus = detail.styleList[0].status

          console.log('this.structs', this.structs)
          const tmpForms = getPrimShowImgs(cloneDeep(this.structs))
          this.forms = tmpForms.map((item, index) => {
            console.log('item', item)
            if (!item) {
              return []
            }
            if (!item.length) {
              item.push(
                cloneDeep({
                  ...KNIFE_PRO_SHOW_ITEM,
                  styleId: this.structs[index] && this.structs[index].id
                })
              )
            } else {
              item.map((sItem) => {
                sItem.styleId = this.structs[index] && this.structs[index].id
                sItem.coord_show = sItem.coord_show
                  ? [
                      {
                        path: sItem.coord_show
                      }
                    ]
                  : []
                sItem.white_show = sItem.white_show
                  ? [
                      {
                        path: sItem.white_show
                      }
                    ]
                  : []

                sItem.shadow_show = sItem.shadow_show
                  ? [
                      {
                        path: sItem.shadow_show || ''
                      }
                    ]
                  : []
                if (Array.isArray(sItem.show_masks) && sItem.show_masks.length) {
                  sItem.show_masks = sItem.show_masks.map((mItem) => {
                    const { map_json, mask } = mItem
                    return [{ path: map_json }, { path: mask }]
                  })
                }
              })
            }
            return item
          })
          this.oForms = cloneDeep(this.forms)
          this.updateUrls()
          this.uploadCoordPic()
          this.watchForms()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.uploadProShowComponent {
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
    ::v-deep {
      .el-tabs__content {
        flex: 1;
        overflow-y: auto;
      }
    }
  }
  .customClass {
    border: 1px solid $border-color;
    margin-bottom: 15px;
    min-height: 500px;
  }
  .struct-item-wrapper {
    position: relative;
    .del-btn,
    .calc-btn {
      position: absolute;
      top: 0px;
      right: 0;
      z-index: 100;
      cursor: pointer;
    }
    .calc-btn {
      top: 30px;
      right: 1px;
      color: $color-primary;
      z-index: 1001;
    }
    .el-image {
      position: absolute;
      // height: 100%;
      width: 400px;
      z-index: 1000;

      top: 30px;
      right: 5px;
      padding: 10px;
      height: auto;
      border: 1px solid $border-color;
      box-shadow: 0 0 5px 1px #999;
    }
    .el-image::before {
      content: '设计区域参考图';
      color: $color-primary;
      line-height: 1;
      position: absolute;
    }
  }
  .config-list {
    margin-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    width: 720px;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
    box-shadow: 0 0 10px 1px #999;
  }
  .config-item {
    margin-top: 10px;
    padding: 10px 10px 0 10px;
    padding-left: 20px;
    display: block;
    width: 350px;
    border: 1px solid $border-color;
  }
  .config-item:nth-child(2n) {
    border-left: none;
  }
  .card {
    width: 100%;
  }
  .el-tabs {
    background: #fff;
    padding: 10px;
  }

  .bottom {
    .el-button {
      width: 150px;
    }
  }
}
</style>
